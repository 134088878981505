.notfound {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 4rem;
  font-weight: bolder;
}

.notfound a {
  font-size: 3rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
}

.notfound a:hover {
  text-decoration: underline;
  color: blue;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

label {
    display: block;
    margin-top: 1rem;
    margin-bottom: 10px;
}

.address {
    margin-top: 10px;
}
select {
    padding: 8px 10px;
}

.Modale {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(2, 2, 2, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    &_content {
        border-radius: 1rem;
        padding: 1rem 2rem;
        border: 1px solid black;
        background-color: white;
        width: auto;
        height: auto;
    }
}

.notfound{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    font-weight: bolder;
    & a {
        font-size: 3rem;
        text-decoration: none;
        color: rgba($color: #000000, $alpha: .8);
        &:hover{
            text-decoration: underline;
            color: blue;
        }
    }
}